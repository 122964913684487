<template>
  <Header />
  <Loader v-if="loading"/>
  <div v-if="!loading" v-html="content" class="content content-wrapper"></div>
</template>

<script>

export default {
  name: 'ContentPage',
  data() {
    return {
      content: '',
      loading: true,
    };
  },
  methods: {
    async getContent() {
      try {
        const routeName = this.$route.name;
        const footerLinkPosition = this.footerLinks.findIndex((element) => element.type === routeName);
        const { data } = await this.axios.get(`${process.env.VUE_APP_API}/content/v1/page/${this.footerLinks[footerLinkPosition].page}`);
        this.content = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getContent();
  },
  computed: {
    footerLinks() {
      return this.$store.state.options.footerLinks;
    },
  },
};
</script>

<style>

</style>
